import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable  } from '@angular/core';
import { Observable } from 'rxjs';
import { rap$ } from '../roles';
import { getCurrentResourceStrings } from 'igniteui-angular';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(localStorage.getItem('token'))
      {
        return true;
      }
      else
      {
        this.router.navigate(['/login']);
        return false;
      }



    new Promise((resolve, reject) => {
      // //console.log("is resolving")
      if (localStorage.getItem('token')) {
        // //console.log("is resolved",localStorage.getItem('token'))
        resolve(true);
      } else {
        this.router.navigate(['/home']);
        resolve(false);
      }

    });
  }

}


@Injectable()
export class PaymentGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      return true;
    if (rap$.isPaymentDone) return true;
    else this.router.navigate(['/dashboard/checkout'])
  }
}

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor( private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if(localStorage.getItem('token'))
      {
        this.router.navigate(['/dashboard/summary']);
        return false;
      }
      else
      {
        return true;
      }
  }
}

@Injectable()
export class PnQGuard implements CanActivate{
  constructor( private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {


   return false;
  }

}

@Injectable()
export class CompanyOnboardingGuard implements CanActivate{
  constructor( private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      let user=JSON.parse(localStorage.getItem('user'));
      if(user?.onboarding_status=='company_onboard')
      {
        return true;
      }
     
      this.router.navigate([getRoute(user?.onboarding_status,user)]);
      return false
  }

}

@Injectable()
export class StoreOnboardingGuard implements CanActivate{
  constructor( private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      let user=JSON.parse(localStorage.getItem('user'));
    if(user?.onboarding_status=='store_onboard' || user?.onboarding_status=='completed')
      {
        return true;
      }
      this.router.navigate([getRoute(user?.onboarding_status,user)]);
      return false;
  }
  

}

@Injectable()
export class IntegrationOnboardingGuard implements CanActivate{
  constructor( private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      let user=JSON.parse(localStorage.getItem('user'));
      if(user?.onboarding_status=='add_integration' || user?.onboarding_status=='completed') 
      {
          return true;
      }
      this.router.navigate([getRoute(user?.onboarding_status,user)]);
      return false;
  }
  

}

@Injectable()
export class DashboardGuard implements CanActivate{
  constructor( private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
 let user=JSON.parse(localStorage.getItem('user'))
     if(!user?.onboarding_status || user?.onboarding_status=='completed')
      {
        return true;   
      }
      this.router.navigate([getRoute(user?.onboarding_status,user)]);
     return false;
  }

}




@Injectable()
export class companyAdminGuard implements CanActivate{
  constructor( private router: Router,private route:ActivatedRoute) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      let user=JSON.parse(localStorage.getItem('user'));
  const back_navigation=   next.data["back_navigation"] as Array<string>
     if(user?.role?.role=='company_admin')
      {
        return true;   
      }
      this.router.navigate(back_navigation);
     return false;
  }

}

const getRoute=(status,user?)=>{
switch(status){
  case 'company_onboard':return 'onboard/company';
  case 'store_onboard':return 'onboard/brand';
  case 'add_integration':
  {
    !user?.store_id&&localStorage.clear()
    return user?.store_id?`onboard/${user?.store_id}/marketplace`:'login';
  };
  default:return 'dashboard/summary';
}
}