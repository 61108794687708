// export const registerUser = 'assets/api/imageList.json';

import { environment } from "environments/environment";

// export const getAllMaster = '/api/distribution/getAllMaster';
const urlVersion = '/api/v1';

export const userRegistration = urlVersion + '/registration';
export const userLogin = urlVersion + '/login';
export const refreshToken = urlVersion + '/refresh-token';
export const googleRegistration = urlVersion + '/google?actionType=register';
export const googleLogin = urlVersion + '/google?actionType=login';
export const userActivateToken = urlVersion + '/activate-user/';
export const verifyResetPasswordToken = urlVersion + '/check-password-token?token=';
export const forgetPassword = urlVersion + '/forget-password?email=';
export const updatePassword = urlVersion + '/reset-password?token=';
export const resetPassword = urlVersion + '/updatepassword';
export const companyChange = urlVersion + '/companyChange';
export const contact = urlVersion + '/contact';


export const getUserProfile = urlVersion + '/getUserProfile';

//Dashboard API
export const createStore = urlVersion + '/createStore';
export const updateStore = urlVersion + '/updateStore';
export const getIntegrationsByServiceId = urlVersion + '/getIntegrationsByServiceId?service_id=';
export const getIntegrationsByIntegrationId = urlVersion + '/getIntegrationByIntegrationId?integration_id=';
export const getServices = urlVersion + '/getServices';
export const verifyIntegration = urlVersion + '/verifyIntegration';

export const getTooltipMessages = urlVersion + '/dashboard/tooltip-message';

export const getStores = urlVersion + '/getStores?user_id=';
export const getServiceByStoreId = urlVersion + '/getServicesByStoreId?store_id=';
export const getStoresByUserId = urlVersion + '/getStoresByUserId?user_id=';
export const getSalesGrowth = urlVersion + '/dashboard/getSalesGrowth?service_id=';
export const getCountryOverview = urlVersion + '/dashboard/countries-overview?store_id=';
export const getGMVUnitsGrowth = urlVersion + '/dashboard/store-growth-units-gmv?service_id=';
export const  getMTDYTDGrowth = urlVersion + '/kpi/get-mtd_ytd-sales?service_id=';
export const  getMTDMix = urlVersion + '/dashboard/brand-mtd-percentage?store_id=';
export const getAllStoresWithStatsByUserId = urlVersion + '/getAllStoresWithStatsByUserId';
export const getRolesByUid = urlVersion + '/getRolesByUid?id='
export const getUserById = urlVersion + '/getUserById?id='
export const getUserStoreService = urlVersion + '/getUserStoreService';
export const getUserStoreServiceIntegrations = urlVersion + '/getUserStoreServiceIntegrations';
export const getPlans = urlVersion + '/getPlans';
export const getStoreByStoreId = urlVersion + '/getStoreByStoreId?store_id=';
export const salesOrderForecast = urlVersion + '/dashboard/sales-order-forecast';
// integration by store and service id API
export const getStoreServiceIntegrations = urlVersion + '/dashboard/store-service-integrations';

//User API
export const addServiceToStore = urlVersion + '/addServiceToStore';
export const deleteServiceFromStore = urlVersion + '/deleteServiceFromStore';
export const addPlanToStoreService = urlVersion + '/addPlanToStoreService';

export const getPlansByServiceId = urlVersion + '/getPlansByServiceId?service_id=2';
export const updateIntegrationToStore = urlVersion + '/updateIntegrationToStore';
export const addIntegrationToStore = urlVersion + '/addIntegrationToStore';
export const getAccountDetailsByUserId = urlVersion + '/getAccountDetailsByUserId?user_id=';
export const deleteStoreByStoreId = urlVersion + '/deleteStoreByStoreId?store_id=';
export const deleteIntegrationById = urlVersion + '/deleteIntegrationById';
export const getCountries=urlVersion+'/kpi/get-countries?store_id=';
export const deactivateStoreByStoreId = urlVersion + '/deactivateStoreByStoreId?store_id=';
export const getServicesByStoreId = urlVersion + '/getServicesByStoreId?store_id=';
export const getDepartments = urlVersion + '/getDepartments';
export const getStoresByUId = urlVersion + '/getStores';
export const updateAccountDetailsByUserId = urlVersion + '/updateAccountDetailsByUserId';
export const myTeam = urlVersion + '/myTeam';
export const getUserByStoreId = urlVersion + '/getUserByStoreId?store_id=';
export const getStoresWithStatsByIDs = urlVersion + '/getStoresWithStatsByIDs';
export const getUsersByCompanyId = urlVersion + '/getUsersByCompanyId?company_id=';
export const addMemberToStore = urlVersion + '/addMemberToStore';
export const deleteMemberFromStore = urlVersion + '/deactiveMemberAccount';
export const removeMemberFromStore = urlVersion + '/removeStoreMember/';
export const updateAccountDetailsByMemberId = urlVersion + '/updateAccountDetailsByMemberId';
export const isMemberAlreadyExists = urlVersion + '/isMemberAlreadyExists?email=';
export const getApplicationsByStoreId = urlVersion + '/getApplicationsByStoreId';
export const getPaymentsList = urlVersion + '/getPaymentsList';

// roles api

export const getRoles = urlVersion + '/getRoles';
export const addRole = urlVersion + '/createRole';
export const updateRole = urlVersion + '/updateRole';
export const updateRoleForUsers = urlVersion + '/updateRoleForUsers';
export const getMemberForRolesByRoleId  = urlVersion + '/getMemberForRolesByRoleId';


// Dashboard Visualization API


export const getCampaignAnalyticsOrders = urlVersion + '/dashboard/campaign-analytics/orders';

export const getTotalSales = urlVersion + '/dashboard/total-sales';
export const getTotalSalesNOrders = urlVersion + '/dashboard/total-sales-orders';
export const getTotalOrders = urlVersion + '/dashboard/total-orders';
// Five Star
export const getTotalOrdersFiveStar = urlVersion + '/dashboard/total-orders';
export const geEmailSchedulerFiveStar = urlVersion + '/dashboard/total-orders';
export const geEmailSentFiveStar = urlVersion + '/dashboard/total-orders';
export const getActiveSKu = urlVersion + '/dashboard/total-active-sku';
export const getOutOfStockSku = urlVersion + '/dashboard/out-of-order-sku';
export const getSalesPerformance = urlVersion + '/dashboard/sales-performance';
export const getSalesMix = urlVersion + '/dashboard/sales-mix';
export const getMarketPlaceOverview = urlVersion + '/dashboard/market-place-overview';
export const getBrandSalesPerformance = urlVersion + '/dashboard/brand-sales-performance';
export const getOverdueOrders = urlVersion + '/dashboard/overdue-orders';
export const getTopPerformingSku = urlVersion + '/dashboard/top-performing-sku';
export const getWorstPerformingSku = urlVersion + '/dashboard/worst-performing-sku';
export const getInventoryStatus = urlVersion + '/dashboard/inventory-status';
export const getDispatchStatus = urlVersion + '/dashboard/dispatch-status';
export const getDispatchStatusByMarketplaceId = urlVersion+'/dashboard/dispatch-status-by-marketplace';

export const getOverdueOrdersByMarketplace = urlVersion+'/dashboard/overdue-orders-by-marketPlace';


export const getSalesMixByMarketplace = urlVersion+'/dashboard/sales-mix-by-marketplace';
export const getMarketplacePerformance = urlVersion+'/dashboard/marketplace-performance';


// Faq Endpionts
export const faqListEndpoint = urlVersion+'/faq';

// Country Endpionts
export const CountryList = urlVersion+'/countries';
export const statesList = urlVersion+'/states/';

// Privileges
export const getRoleDetails = urlVersion+'/getRoleDetails';
export const servicesWithPrivileges = urlVersion+'/servicesWithPrivileges';
export const getServicesPrivileges = urlVersion+'/getServicesPrivileges';

export const getAllUsersByCompany = urlVersion + '/getAllUsersByCompany'
export const getAllRolesByCompany = urlVersion + '/getAllRolesByCompany'


//Repay Endpoints
export const getRepayCaseManager  = urlVersion + '/repay/caseManager/';
export const getInboundCaseManager  = urlVersion + '/repay/inbound-caseManager/';
export const getPOsList  = urlVersion + '/repay/POs-list/';
export const getreAssignCase = urlVersion + '/repay/reassign-case/';
export const getFBACaseManager  = urlVersion + '/repay/fba-caseManager/';
export const getLostCaseManager  = urlVersion + '/repay/lost-caseManager/';
export const getDamagedCaseManager  = urlVersion + '/repay/damaged-caseManager/';
export const getRepayAdjustment  = urlVersion + '/listings/item/2?user_id=';
export const getRepayShipMent  = urlVersion + '/shipments/item/2?user_id=';
export const getShipmentsList = urlVersion + '/repay/shipments/list/';
export const getInventory = urlVersion + '/repay/inventory/';
export const getListings = urlVersion + '/repay/listings/';
export const getFBAListingsItem = urlVersion + '/repay/inventory/listing/';
export const getInventoryItem = urlVersion + '/repay/inventory/item/';
export const getInventoryHistory = urlVersion + '/repay/inventory/history/';
export const getInventoryReimbursements = urlVersion + '/repay/inventory/reimbursements/';
export const getShipmentsItem = urlVersion + '/repay/shipments/item/';
export const updateShipmentCase = urlVersion + '/repay/shipment/update-case-status/';
export const brandOperationalUsers=urlVersion+`/repay/brand-operation-user-list/`;
export const updateReconcilationDate = urlVersion + '/repay/shipments/update-reconcilation-date/';
export const updateFieldValue = urlVersion + '/repay/shipments/update-field-value/';
export const updatefollowUpDate = urlVersion + '/repay/shipments/update-followup-date/';
export const getdismissCase = urlVersion + '/repay/dismiss-case/';

// repay dashboard
export const getDashboardActualVsEstAmountKPI = urlVersion + '/repay/dashboard/card/actual-vs-expected-amount-kpi/';
export const getDashboardPerformanceKPI = urlVersion + '/repay/dashboard/card/performance-kpi/';
export const getDashboardAggregatedReimburmentAmoutKPI = urlVersion + '/repay/dashboard/card/aggregated-reimbursed-amount-kpi/';
export const getRepayIntegrations = urlVersion + '/repay/get-repay-integrations/';


export const createPaymentIntent = urlVersion+'/dashboard/create-stripe-intent';
export const postPayment = urlVersion+'/dashboard/post-payment-data';



// Product Data

export const getConfig       = urlVersion + '/keepa/getConfig';
export const updateConfig    = urlVersion + '/keepa/updateconfig';
export const getProcesslog   = urlVersion + '/keepa/getProcesslog';
export const getActivitylog  = urlVersion + '/keepa/getActivitylog';
export const getRequestLogsByServiceUsageIds = urlVersion+'/getRequestLogsByServiceUsageIds'
export const createRequestLogs = urlVersion+'/createRequestLogs'

// PNQ EndPoints

export const getToken = urlVersion +'/login';
export const getAllTools = urlVersion +'/instances';
export const getAllInstances = urlVersion +'/instances';
export const getSettingByTool = urlVersion +'/settings/';
export const testPriceMatrix =urlVersion + '/test-matrix';
export const getInstancesById = urlVersion +'/instances/';
export const getkeysByInstancesId = urlVersion +'/keys';
export const getAllDomains =urlVersion + '/domains';
export const getAllMarkets = urlVersion +'/markets';
export const uploadAndImportAsins=urlVersion +'/upload';
export const getAllImportLog=urlVersion +'/import-log';
export const downloadFile=urlVersion +'/download';
export const getProcessLog=urlVersion +'/process-log';
export const addKey=urlVersion +'/keys/';
export const deleteKey=urlVersion +'/keys/';
export const updateKey=urlVersion +'/keys/';
export const updateSettings=urlVersion +'/settings/';
export const updateSettingsMultiple=urlVersion +'/settings-multiple/';
export const getAllActivityLog=urlVersion +'/activities';
export const getAllActivityNames=urlVersion +'/activity/names';
export const getDecryptKey=urlVersion +'/keys-decrypt/';

// Scrapper EndPoints

export const getDownloadFailedData = '/download_failed_data';
export const downloadProcessedData = '/download_processed_data';
export const currentStatus = '/current_status';
export const uploadFileAmazonUsa = '/upload_file_amazon_usa';
export const activityLogs = '/activity_logs';
export const allScrappersDetails = '/all_scrappers_details';
export const save_scrapper_credentials = '/save_scrapper_credentials';
export const getCredentials = '/get_scrapper_credentials';
export const getAutoScrapperDetails  ='/auto_scraper_details';
export const allRunningcrapperDetails  ='/all_running_scrappers_details';


// Hijack Endpoints
export const getHijackTableList = urlVersion + '/hijack/getHijackList';
export const updateHijackAlert = urlVersion + '/hijack/updateAlert';

// Restock Endpoints
export const getRestockTableList = urlVersion + '/restock/getRestockList';
export const updateAlertEmail = urlVersion + '/restock/updateAlertEmail';
/*Onboarding */


export const onboardStore  ='/onboardStore';
export const getIntegrationTypeByService  ='/getIntegrationTypeByService/';
export const getIntegrationByTypeAndService='/getIntegrationByTypeAndService/';
export const verifyIntegrationCredentials='/verifyIntegrationCredentials';
export const changeServiceIntegrationStatus='/integration/changeServiceIntegrationStatus';
export const getStoreServiceIntegration='/integration/getStoreServiceIntegrations'

export const changeStoreStatus='/changeStoreStatus';

export const getServiceDetails='/getServiceDetails';
export const submitServiceEnquiry='/submitServiceEnquiry';


/************* AMAZON ONBOARDING URLS ************/
export const amazon={
    getSellerAuth:'/integration/get-seller-auth',
    verifyIntegration:'/integration/verify-integration'
};

/************  SUPPRESSED LISTING URLS  ***************/

export const suppressed_listing={
    parent:'dataone',
    products_status:'suppressed_listing',
    products_issues:'suppressed_reasons',
    products_list:'suppressed_product_list'
}


export const  buildURL=function(type,endpoint)
{
    const api_type=this[type];
    return  `${environment.baseUrl}${urlVersion}/${api_type.parent}/${api_type[endpoint]}`
}


