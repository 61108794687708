import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetasxService {

  constructor(private http: HttpClient) { }
  private readonly HOSTNAME = environment.vendordashboard_url;
  getdata(storeId: any, vendorName: any, startDate: any, endDate: any, businessType: any, apiurk: any, category_data: any, brand_manager_name: any) {
    const businessValue = businessType || 'FBM';
    const vendorNameValue = vendorName || 'MODWAY';
    const url = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/${apiurk}`;
    const categoryDataValue = category_data || '45';
    const brandManagerNameValue = brand_manager_name || '203';

    let params = new HttpParams()
      .set('store_id', storeId)
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('business_type', businessValue)
      .set('vendor_name', vendorNameValue)
      .set('category_name', categoryDataValue)
      .set('brand_manager_name', brandManagerNameValue);

    return this.http.get(url, { params });
  }

  getSkuData(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/sku-details?store_id=163&startDate=${startDate}&endDate=${endDate}-13&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }

  getSkusales(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/sales-by-year-sku?store_id=163&startDate=${startDate}&endDate=${endDate}&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }

  getSkuDetailsData(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/total-sales-count-sku?store_id=163&startDate=${startDate}&endDate=${endDate}&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }


  getOrderCountQuantityApi(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/order-vs-quantity-sku?store_id=163&startDate=${startDate}&endDate=${endDate}&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }

  getWeekOnWeekSalesApi(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/week-on-week-sales-sku?store_id=163&startDate=${startDate}&endDate=${endDate}&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }

  getHistoricPrice(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/get-historic-pricing?store_id=163&startDate=${startDate}&endDate=${endDate}&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }

  getHistoricInv(sku: any, startDate: any, endDate: any) {
    const skuValue = '' ? 'APEX-3GFUEL' : sku
    const skuUrl = `${this.HOSTNAME}api/v1/virventures-vendor-dashboard/get-historic-inventory?store_id=163&startDate=${startDate}&endDate=${endDate}&business_type=FBM&sku=${skuValue}`;
    return this.http.get(skuUrl)
  }

  getVendorWiki(route: any, value: any) {
    const url = `https://app.virventures.com/${route}.php?SupplierPrefix=${value}`;
    return this.http.get(url)
  }


  getVendorPrefixNames() {
    const url = `https://app.virventures.com/SupplierDetails.php`;
    return this.http.get(url)
  }

  getVendorWikiChart(route: any, value: any) {
    const url = `https://app.virventures.com/${route}.php?SupplierPrefix=${value}`;
    return this.http.get(url)
  }

  getRMAData(prefix: any, year: any) {
    const url = `https://app.virventures.com/RmaCharts.php?SupplierPrefix=${prefix}&Year=${year}`;
    return this.http.get(url)
  }

}

