import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
import * as serviveUrl from 'app/shared/commons/service-url';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ɵBrowserDomAdapter } from '@angular/platform-browser';
import { CommonDataObservableService } from './commondata.service';
import { Service } from '../models';
import { parse } from 'path';
import { count } from 'console';
import { E } from '@angular/cdk/keycodes';
const default_growth_stats_data={
  mtd_ytd:[{label:'MTD',period:(new Date()),type:'mtd',sales:'$0',sales_label:'Sales',target_label:'Target',last_year:{sales:'$0',target:'$0',target_achieved:0},achieved_label:'Achieved',target:'$0',target_achieved:'0'},{label:'YTD',period:(new Date()),sales_label:'Sales',target_label:'Target',achieved_label:'Achieved',type:'ytd',sales:'$0',target:'$0',target_achieved:'0'}],
  order_units:[{type:'units',units:{label:'Total Units',value:'0'},gmv:{label:'GMV',value:'0'}}]};
interface CommonResponse {
  response_code: number
  response_message: string;
  response_body: any;
}
interface Store {
  user_id: number;
  logo: string;
  store_name: string;
  company_id: number;
  store_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private myDataSubject = new BehaviorSubject<boolean>(true); // Initialize with an empty string
  mydata = this.myDataSubject.asObservable();
  // dataOneClass = new BehaviorSubject('showDataOne == true');
  moveToScreen=new Subject();
  isOpened=new Subject();
  moveToApp=new Subject();
  private dataOneClass: BehaviorSubject<string> = new BehaviorSubject('');
  countries=[];
  mtdMix=new Subject();
  mtdMixData=[];
growth_stats=new BehaviorSubject(default_growth_stats_data.mtd_ytd);
order_units_stats=new BehaviorSubject(default_growth_stats_data.order_units);
growth_stats_data={mtd_ytd:[],order_units:[]};
public selected_brand_id=0;
dashboardSalesGrowth={month:[],year:[],selected_sales_growth:new BehaviorSubject(null),filter_years:['2022', '2021','2020']};
services_list=[];
store_services=[];
  public httpOption;
  constructor(public http: HttpClient, private commonService: CommonDataObservableService) {
    this.httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public get Data(): Observable<string>{
    return this.dataOneClass.asObservable();
  }
  SetDataOneClass(css_class: string){
    this.dataOneClass.next(css_class);
  }

  getWareHouseStatus(id){
    return this.http.get(environment.baseUrl+`/api/v1/dashboard/warehouse-status?store_id=${id}`).toPromise();
  }
  createStore(formData: { logo, store_name, user_id, company_id }) {
    // //console.log('formData', formData)
    // return this.http.post<any>(`${environment.baseUrl + serviveUrl.uploadFile}`, formData, {
    return this.http.post<any>(environment.baseUrl + serviveUrl.createStore, formData, {

      headers: new HttpHeaders({ "Accept": "application/json" }),
      observe: 'events',
      reportProgress: true
    }).pipe(
      // map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );
  }
  verifyIntegration(data: {
    integrationId: any;
    mwskey: string;
    marketNickname: string;
    sellerid: string;
  }) {
    const body = {
      ...data
    }
    return this.http.post<any>(environment.baseUrl + serviveUrl.verifyIntegration, body, {
      headers: new HttpHeaders({ "Accept": "application/json" })
    }).pipe(
      catchError(this.handleError)
    );
  }
  

getCountries(store_id)
{
  return this.http.get<any>(environment.baseUrl + serviveUrl.getCountries+store_id).pipe(catchError(this.handleError));
}

  addServiceToStore = (data) => this.http.post<any>(environment.baseUrl + serviveUrl.addServiceToStore, data).pipe(catchError(this.handleError));

  deleteServiceFromStore = (id: string) => this.http.delete<any>(environment.baseUrl + serviveUrl.deleteServiceFromStore, { params: { id }}).pipe(catchError(this.handleError));

  addIntegrationToStore = (data: {
    integration_id: number;
    integration_key: { sellerid: string; mwskey: string; marketNickname: string };
    service_id: number;
    store_id: number;
    status: string;
  }) =>  {
    return this.http.post<any>(environment.baseUrl + serviveUrl.addIntegrationToStore, data).pipe(catchError(this.handleError));
  }

  deleteIntegrationById = (id: any): Observable<any> =>  this.http.delete(environment.baseUrl + serviveUrl.deleteIntegrationById, { params: { id } }).pipe(catchError(this.handleError));

  getApplicationsByStoreId = (store_id: any,service_id?): Observable<any> =>  this.http.get(environment.baseUrl + serviveUrl.getApplicationsByStoreId, { params: { store_id,service_id } }).pipe(catchError(this.handleError));

  getIntegrations(storeId: any , serviceId:any,appIntegrations?:any): Observable<any> {
    return this.http.get(`${environment.baseUrl}${serviveUrl.getIntegrationsByServiceId}${serviceId}&store_id=${storeId}&appIntegrations=${appIntegrations?true:false}`).pipe(catchError(this.handleError));
  }
  getUserProfile(): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.getUserProfile).pipe(catchError(this.handleError));
  }

  getTooltipMessages(service: string, lang: string): Observable<any> {

    return this.http.get(`${environment.baseUrl}${serviveUrl.getTooltipMessages}/${service}/${lang}`).pipe(catchError(this.handleError));
  }
  getServices(store_id?): Observable<any> {
    const store_filter=store_id?'?store_id='+store_id:'';
    return this.http.get(environment.baseUrl + serviveUrl.getServices+store_filter).pipe(catchError(this.handleError));
  }
  getStoresByUserId(userId: any, isCompany = 0): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getStoresByUserId + userId + '&isCompany='+isCompany).pipe(catchError(this.handleError));
  }

  ///sales-growth-kpi
  getSalesGrowth(store_id: any,year,last_months='',marketplace_id,country_code,service_id?): Observable<CommonResponse> {
    service_id=service_id||2;
    const store_filter=store_id&&store_id.length?store_id.map(e=>`&store_id=${e}`).join(''):'';
    const integration_filter=marketplace_id&&marketplace_id.length?marketplace_id.map(e=>`&marketplace_id=${encodeURIComponent(e)}`).join(''):'';
    const country_filter=country_code&&country_code.length?country_code.map(e=>`&country_code=${encodeURIComponent(e)}`).join(''):'';
    const combined_filter=store_filter+integration_filter+country_filter;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getSalesGrowth + service_id+'&year='+(last_months?(new Date()).getFullYear():year)+'&last_months='+last_months+`${combined_filter}`).pipe(catchError(this.handleError));
  }
  
  getMTDYTDGrowth(store_id: any,year,marketplace_id,country_code,is_company,service_id?): Observable<CommonResponse> {
     service_id=service_id||2;
    const store_filter=store_id&&store_id.length?store_id.map(e=>`&store_id=${e}`).join(''):'';
    const integration_filter=marketplace_id&&marketplace_id.length?marketplace_id.map(e=>`&marketplace_id=${encodeURIComponent(e)}`).join(''):'';
    const country_filter=country_code&&country_code.length?country_code.map(e=>`&country_code=${encodeURIComponent(e)}`).join(''):'';
    const combined_filter=store_filter+integration_filter+country_filter;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getMTDYTDGrowth + 2+'&year='+year+`&is_company=${is_company}${combined_filter}`).pipe(catchError(this.handleError));
  }
  
  getUnitsGMVGrowth(store_id: any,marketplace_id,{start_date,end_date,country_code}): Observable<CommonResponse> {
    const store_filter=store_id&&store_id.length?store_id.map(e=>`&store_id=${e}`).join(''):'';
    const integration_filter=marketplace_id&&marketplace_id.length?marketplace_id.map(e=>`&marketplace_id=${encodeURIComponent(e)}`).join(''):'';
    const country_filter=country_code&&country_code.length?country_code.map(e=>`&country_code=${encodeURIComponent(e)}`).join(''):'';
    const combined_filter=store_filter+integration_filter+country_filter;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getGMVUnitsGrowth + 2+`&start_date=${start_date}&end_date=${end_date}${combined_filter}`).pipe(catchError(this.handleError));
  }
  getMtdMixData(store_id,marketplace_id,country_code){
    const integration_filter=marketplace_id&&marketplace_id.length?marketplace_id.map(e=>`&marketplace_id=${encodeURIComponent(e)}`).join(''):'';
    const country_filter=country_code&&country_code.length?country_code.map(e=>`&country_code=${encodeURIComponent(e)}`).join(''):'';
    const combined_filter=store_id+integration_filter+country_filter;
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getMTDMix + combined_filter).pipe(catchError(this.handleError));
  }
  getAllStoresWithStatsByUserId(user_id: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getAllStoresWithStatsByUserId, { params: { user_id }}).pipe(catchError(this.handleError));
  }
  getUserByStoreId(storeId: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getUserByStoreId + storeId).pipe(catchError(this.handleError));
  }
  getServiceByStoreId(storeId: any,multiple?): Observable<CommonResponse> {
    if(multiple){
      storeId=(storeId&&storeId.length)?storeId.map((e,i)=>i?`&store_id=${e}`:e).join(''):'';
    }
    return this.http.get<CommonResponse>(environment.baseUrl + serviveUrl.getServiceByStoreId + storeId).pipe(catchError(this.handleError));
  }
  getStoresByStoreId(storeId: any): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.getStoreByStoreId + storeId).pipe(catchError(this.handleError));
  }
  getForecastByStoreId(store_id: any): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.salesOrderForecast, { params: { store_id } }).pipe(catchError(this.handleError));
  }
  updateStore(data: any): Observable<CommonResponse> {
    
    return this
      .http
      .post<CommonResponse>(environment.baseUrl + serviveUrl.updateStore, data).pipe(catchError(this.handleError));
  }

  //Get Integrations by store and service id

  getStoreServiceIntegrations(data:any): Observable<any> {
    const {store_id,service_id}=data
    return this.http.get(environment.baseUrl + serviveUrl.getStoreServiceIntegrations, { params: { store_id,service_id } }).pipe(catchError(this.handleError));
  }

  getUserStoreServiceIntegrations(userId: any, service_id: any, storeId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl + serviveUrl.getUserStoreServiceIntegrations}?user_id=${userId}&service_id=${service_id}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  getUserStoreService(userId: any, storeId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl + serviveUrl.getUserStoreService}?user_id=${userId}&store_id=${storeId}`).pipe(catchError(this.handleError));
  }
  getIntegrationByIntegrationId(integrationId: any): Observable<any> {
    return this.http.get(environment.baseUrl + serviveUrl.getIntegrationsByIntegrationId + integrationId).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    let fError;
    if (error) {
      if (error.error) {
        fError = error.error;
      }
    } else {
      fError = {
        error: {}
      }
    }
    // return an observable with a user-facing error message
    return throwError({ message: 'Something bad happened. Please try again later.', error: fError });
  }



  refereshStoreData() {
    this.commonService.responseData({
      type: 'store'
    })
  }

  async setSalesGrowth(store_id,year,type,marketplace_id,country_code){
   let growth_data=type=='month'?
   this.dashboardSalesGrowth[type].find(e => e.store_id == store_id.join('_') &&e.year == year&&e.marketplace_id==marketplace_id.join('_')&&e.country_code==country_code.join('_')):
   this.dashboardSalesGrowth[type].find(e => e.store_id == store_id.join('_')&&e.marketplace_id==marketplace_id.join('_')&&e.country_code==country_code.join('_'));{
    const filter_key=type=='month'?'month_name':'year';
    
    if(growth_data){
      
      this.dashboardSalesGrowth.selected_sales_growth.next(growth_data);
       return;
     }
     try {
      let res: any = await this.getSalesGrowth(store_id, type=='month'?year:'',parseInt(year)||type=='year'?'':'true',marketplace_id,country_code).toPromise();
      const { response_body } = res;
      if (response_body) {
      const {data:budget_data,total_budget,total_sales,average_growth_percentage,total_growth_percentage}=response_body;
      const sales= budget_data.map(e => e.total_sales), store_budget=budget_data.map(e => e.store_budget), categories= budget_data.map(e =>e[filter_key].toString().trim());
      const growth_percentage=total_growth_percentage || 0;
      
      growth_data=type=='month'?{growth_percentage,store_id:store_id.join('_'),marketplace_id:marketplace_id.join('_'),country_code:country_code.join('_'),year:year,sales,store_budget,month_name:categories,total_sales,total_budget,average_growth_percentage}:
       {store_id:store_id.join('_'),year:categories,sales,store_budget,total_sales,total_budget,marketplace_id:marketplace_id.join('_'),country_code:country_code.join('_'),growth_percentage,average_growth_percentage};
       this.setgrowthsalesData(growth_data,type)
      
      }
    }
    catch (ex) {
      console.log(ex);
    }
   }
  }
  async setMTDYTDSales(store_id,year,is_company,marketplace_id,country_code)
  {
try{
  if(this.growth_stats_data.mtd_ytd.length){
    let stats=this.growth_stats_data.mtd_ytd.find(e=>e.store_id==store_id.join('_')&&e.marketplace_id==marketplace_id.join('_')&&e.country_code==country_code.join('_'));
    if(stats)
    {
      this.growth_stats.next(stats.data);
      return;
    }

  }
let res:any=await this.getMTDYTDGrowth(store_id,year,marketplace_id,country_code,is_company).toPromise();
const {data:stats}=res;
if(stats)
{
  let stats_result=[];
  default_growth_stats_data.mtd_ytd.forEach(e=>{
  let data=stats[e.type];
  if(data)
  {stats_result.push({...e,last_year:{sales:'$'+this.getShortCurrency(data.last_year.sales),target:data.last_year.target,target_achieved:data.last_year.target_achieved},sales:'$'+this.getShortCurrency(data.sales),target:'$'+this.getShortCurrency(data.target),target_achieved:data.target_achieved,mtd_all:marketplace_id.length?null:true});}});
  
 this.growth_stats_data.mtd_ytd.push({store_id:store_id.join('_'),marketplace_id:marketplace_id.join('_'),country_code:country_code.join('_'),data:stats_result}); 
this.growth_stats.next(stats_result);
}

}catch(ex)
{
  console.log(ex);
}
  }
  async setMTDMix(store_id,marketplace_id,country_code)
  {
   try{ 
  
    if(this.mtdMixData.length)
    {
      let mix_data=this.mtdMixData.find(e=>e.store_id==store_id&&e.marketplace_id==marketplace_id.join('_')&&e.country_code==country_code.join('_'));
     if(mix_data){ this.mtdMix.next(mix_data.data)
      return ;}
    }
    let {response_body}:any=await this.getMtdMixData(store_id,marketplace_id,country_code).toPromise();

    if(response_body)
    {
      let d={};
      response_body.forEach(e=>{
        d[e.store_id]=e.mtd;
      });
     let  mix_data={store_id:store_id,marketplace_id:marketplace_id.join('_'),country_code:country_code.join('_'),data:d};
     this.mtdMixData.push(mix_data)
      this.mtdMix.next(d);
    }
  }
    catch(ex)
    {
      console.log(ex)
    }
  }

  async setGrowthUnitsGMV(store_id,marketplace_id,start_date,end_date,country_code)
  {
try{
  if(this.growth_stats_data.order_units.length){
    let stats_data=this.growth_stats_data.order_units.find(e=>e.store_id==store_id.join('_')&&marketplace_id.join('_')==e.marketplace_id&&start_date==e.start_date&&end_date==e.end_date&&e.country_code==country_code.join('_'));
    if(stats_data)
    {
      this.order_units_stats.next(stats_data.data);
      return;
    }
  }
let res:any=await this.getUnitsGMVGrowth(store_id,marketplace_id,{start_date,end_date,country_code}).toPromise();
const {response_body}=res;
if(response_body)
{
  
  let stats_result=[];
  default_growth_stats_data.order_units.forEach(e=>{
  
  let  data=response_body;
  if(data)
  {
    stats_result=stats_result.length?[...stats_result,...[{...e,units:{...e.units,value:this.getShortCurrency(data.units)},gmv:{...e.gmv,value:''+this.getShortCurrency(data.gmv)}}]]:[{...e,units:{...e.units,value:this.getShortCurrency(data.units)},gmv:{...e.gmv,value:''+this.getShortCurrency(data.gmv)}}];
  }

});
// this.growth_stats_data.order_units.push({store_id:store_id.join('_'),marketplace_id:marketplace_id.join('_'),start_date,end_date,data:stats_result,country_code:country_code.join('_')});
 this.order_units_stats.next([...stats_result]);
}

}catch(ex)
{
  console.log(ex);
}
 }
 

   

  setgrowthsalesData(growth_data,type)
  {
    
            this.dashboardSalesGrowth[type].push(growth_data);
            this.dashboardSalesGrowth.selected_sales_growth.next(growth_data);
  }

  getShortCurrency(value:number)
  {
    if(value/1000000>=1)
          return parseFloat(((value/1000000).toFixed(2))).toLocaleString('en-us') + 'M';
          else if(value/999>=1)
          return parseFloat((value/1000).toFixed(2)).toLocaleString('en-us') + 'K';
    else
    return value&&parseInt(value.toString())?parseInt(value.toString()).toLocaleString('en-us'):0;
  }

  getWordsFirstUpper(str:string,splitter?){
    if(!str)return '';
    if(splitter){str=str.split(splitter).join(' ')};
let words=str.split(' ');
words= words.map(e=> e.length>1?e.toLowerCase().charAt(0).toUpperCase()+e.slice(1).toLowerCase():e);
return words.join(' ');
  }

  dataemit(data: any) {
    this.myDataSubject.next(!this.myDataSubject.value);
  }
}




