import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppHttpInterceptor } from './shared/commons/appHttpInterceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard, PaymentGuard,LoggedInGuard } from 'app/shared/auth/auth-guard.service';
import { ViewBillingDetailGuard, ViewRolesListGuard, ViewUsersListGuard } from 'app/shared/auth/role-guard.service';
import { ToastMessagesComponent } from 'app/shared/component/toaster/toast.component';
import { StoreModule } from '@ngrx/store';
import { ProtectGuard } from 'app/modules/mgaf/protect.guard'
import { EffectsModule } from '@ngrx/effects';
import { metaReducers } from 'app/store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { MetasxService } from 'app/component/dashboard/metasx/metasx.service';
import { fromRoot } from './store';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from '@progress/kendo-angular-charts';




// import 'hammerjs';


@NgModule({
  declarations: [
    AppComponent,
    ToastMessagesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    HttpClientModule,
    StoreModule.forRoot({
      company: fromRoot.companyReducer,
      user: fromRoot.userReducer,
      faq: fromRoot.faqReducer
    }, { metaReducers }),
    EffectsModule.forRoot([fromRoot.RootEffects, fromRoot.FaqEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ChartsModule
  ],
  providers: [
    AuthGuard,
    LoggedInGuard,
    ProtectGuard,
    PaymentGuard,
    LoggedInGuard,
    MetasxService,
    ViewRolesListGuard,
    ViewUsersListGuard,
    
    ViewBillingDetailGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
